import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import definition from "@/game-destiny2/definition.mjs";
import Accounts from "@/hub-settings/Accounts.jsx";
import { PageContainer } from "@/hub-settings/Common.style.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function AccountsDestiny2() {
  const state = useSnapshot(readState);
  const destiny2 = state.settings.loggedInAccounts[definition.shortName];
  const profiles = useMemo(
    () =>
      Object.entries(destiny2 ?? {}).map(([, profile]) => ({
        displayName: profile.bungieName,
        key: profile.bungieId,
        avatar: profile.iconPath,
      })),
    [destiny2],
  );
  return (
    <PageContainer>
      <Accounts
        profiles={profiles}
        gameShort={definition.shortName}
        autoDetectText={[
          "destiny2:settings.autoDetectProfile",
          "Hey! I can automatically detect your profile. Just open Destiny 2 and log into the account you want to connect.",
        ]}
        renderOtherActionItems={undefined}
        renderOtherItems={undefined}
      />
    </PageContainer>
  );
}
